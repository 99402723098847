<template>
  <div class="year-picker">
    <select
      v-model="selectedYear"
      @change="emitYear"
    >
      <option
        v-for="year in years"
        :key="year"
        :value="year"
      >
        {{ year }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'YearPicker',
  data() {
    return {
      selectedYear: this.value,
      years: [],
      endYear: 2034,
      startYear: 2010,
    }
  },
  watch: {
    value(newValue) {
      this.selectedYear = newValue
    },
  },
  mounted() {

  },
  methods: {
    emitYear() {
      this.$emit('input', this.selectedYear)
    },
  },
}
</script>

<style>
.year-picker select {
  padding: 10px;
  font-size: 16px;
}
</style>
