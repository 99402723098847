<template>
  <div class="flex flex-col rounded-[8px] h-fit border overflow-hidden">
    <div class="flex px-[16px] py-[12px] bg-[#F4F4F4]">
      <span class="w-7/12 flex items-center">{{ tableHead }}</span>
      <span class="w-5/12 flex items-center justify-center ml-auto">Jumlah</span>
    </div>
    <div class="flex flex-col max-h-[300px] overflow-auto">
      <div
        v-for="(el, index) in data"
        :key="index"
        class="flex px-[16px] py-[12px] border-b last:border-0"
      >
        <span class="w-7/12 flex items-center">{{ el.name === 'men' ? 'Laki-laki' : el.name === 'women' ? 'Perempuan' : el.name }}</span>
        <span class="w-5/12 flex items-center justify-center ml-auto">{{ el.total }} <span class="ml-[5px] text-[#34A770]">{{ ` (${Math.round((el.total / totalData) * 100)} %)` }}</span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableHead: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    totalData() {
      // `this` points to the vm instance
      return this.data.reduce(((total, el) => total + el.total), 0)
    },
  },
}
</script>
