<template>
  <section class="bg-white invoice p-6 rounded-[8px]">
    <div class="flex">
      <span class="font-semibold text-xl text-[#333]">Invoice</span>
      <div class="filter ms-auto flex gap-[10px]">
        <VSelect
          v-model="filterIsPaid"
          :reduce="e => e.value"
          :options="optionsIsPaid"
          label="text"
          :clearable="false"
          style="width: 120px;"
          @input="getChartInvoice()"
        />
        <VSelect
          v-model="filterType"
          :reduce="e => e.value"
          :options="optionsType"
          label="text"
          :clearable="false"
          style="width: 150px;"
          @input="getChartInvoice()"
        />
      </div>
    </div>
    <Chart
      :series="series"
      :loading="loading"
    />
    <div class="flex gap-[24px] mt-[24px] flex-wrap flex-md-nowrap">
      <Card
        :container-class="'w-full md:w-1/2'"
        :title="'Total Biaya Admin Paid'"
        :prepend-title-icon="'k-card-tick-1'"
        :content="`${IDR(dataTotal.admin_fee)},-`"
        :content-class="'py-[10px]'"
      >
        <template v-slot:footer>
          <div class="flex flex-col">
            <span class="text-[#34A770] text-[12px]">Total partner ditagihkan: {{ dataTotal.total_partner }}</span>
            <span class="text-[#08A0F7] text-[12px]">Total talent yang ditagih invoice : {{ dataTotal.total_talent }}</span>
          </div>
        </template>
      </Card>
      <Card
        :container-class="'w-full md:w-1/2'"
        :title="'Total Sharing Fee'"
        :prepend-title-icon="'k-convert-card'"
        :content="`${IDR(dataTotal.sharing_fee)},-`"
        :content-class="'py-[10px]'"
      />
    </div>
  </section>
</template>

<script>
import { IDR } from '@/libs/currency'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import MonthlyPicker from 'vue-monthly-picker'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import VSelect from 'vue-select'
import Chart from './Chart.vue'
import Card from './CardTotal.vue'
import YearPicker from './YearPicker.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    Chart,
    Card,
    VSelect,
  },
  data() {
    return {
      IDR,
      alertError,
      loading: false,
      series: [],
      dataTotal: {},
      monthlabel: LABELMONTH,
      filterIsPaid: 'paid',
      optionsIsPaid: [
        {
          text: 'Paid',
          value: 'paid',
        },
        {
          text: 'Publish',
          value: 'publish',
        },
      ],
      filterType: 'admin_fee',
      optionsType: [
        {
          text: 'Admin Fee',
          value: 'admin_fee',
        },
        {
          text: 'Sharing Fee',
          value: 'sharing_fee',
        },
      ],
    }
  },
  mounted() {
    this.getChartInvoice()
    this.getTotalInvoice()
  },
  methods: {
    async getChartInvoice() {
      this.loading = true
      const params = `type=${this.filterIsPaid}&fee_type=${this.filterType || 'admin_fee'}&year=${this.limit || 2023}`
      const url = `/v1/dashboard/invoice?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.series = [
            {
              name: 'Customer Service',
              data: data.filter(el => el.skill === 'Customer Service').map(el => el.total),
              color: '#E31A1A',
            },
            {
              name: 'Live Streamer',
              data: data.filter(el => el.skill === 'Live Streamer').map(el => el.total),
              color: '#FBBC05',
            },
            {
              name: 'Advertiser',
              data: data.filter(el => el.skill === 'Advertiser').map(el => el.total),
              color: '#34A770',
            },
            {
              name: 'Admin Marketplace',
              data: data.filter(el => el.skill === 'Admin Marketplace').map(el => el.total),
              color: '#08A0F7',
            },
          ]
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getTotalInvoice() {
      this.loading = true
      const params = `date=${this.date || '2023-05-01'}`
      const url = `/v1/dashboard/total-invoice?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.dataTotal = data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
  },
}
</script>
