<template>
  <div class="chart pb-6 border-b border-dashed">
    <div
      v-if="loading"
      class="w-full h-[350px] flex items-center justify-center"
    >
      <b-spinner
        variant="primary"
      />
    </div>
    <ApexChart
      v-else
      ref="chart"
      :options="options"
      :series="series"
      height="350"
    />
  </div>
</template>

<script>
import ApexChart from 'vue-apexcharts'
import { optionsInvoiceChart, optionsTalentChart } from './config'

export default {
  components: {
    ApexChart,
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    chartOf: {
      type: String,
      default: 'invoice',
    },
  },
  data() {
    return {
      options: this.chartOf === 'invoice' ? optionsInvoiceChart : optionsTalentChart,
    }
  },
}
</script>
